import AOS from 'aos';
import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.min.css';
import Swiper, { Navigation, Pagination, Autoplay, EffectFade , Thumbs, FreeMode } from 'swiper';
// import { PhotoSwipeLightbox } from 'photoswipe';

import PhotoSwipeLightbox from 'photoswipe/dist/photoswipe-lightbox.esm.js';
import scrollSpy from 'simple-scrollspy'

Swiper.use([Navigation, Pagination, Autoplay, EffectFade, Thumbs, FreeMode]);
window.Swiper = Swiper;

// JavaScript
$(document).ready( function () {
  // scroll top
  $('.back-to-top').click(function(event){
    $("html,body").scrollTop(0);
  });

  // transparent header
  function headerScroll() {
    if ($(window).scrollTop() > 80) {
      $('.header').addClass("min-header");
    } else {
      $('.header').removeClass("min-header");
    }
    if ($(window).scrollTop() > 150) {
      $('.back-to-top').addClass("is-show");
    } else {
      $('.back-to-top').removeClass("is-show");
    }
  }

  headerScroll();
  $(window).on('scroll', function () {
    headerScroll();
  });

  $('.header-mobile-menu').on('click', function () {
    $(this).toggleClass('active');
    $('.header-menu-outer').toggleClass('active');
  });

  $('.menu-link').click(function(e) {
    $(this).parent().toggleClass('active').siblings().removeClass('active');
  });

  var indexBanner = new Swiper('#indexBanner', {
    effect: 'fade',
    autoplay: {
      delay: 5000,
    },
    loop: true,
    speed: 1000,
    pagination: {
      el: ".bn-swiper-pagination",
      clickable: true
    },
  });

  var indexServiceThumbs = new Swiper("#indexServiceThumbs", {
    spaceBetween: 19,
    slidesPerView: 7,
    watchSlidesProgress: true,
    navigation: {
      prevEl: ".index-service-thumb-prev",
      nextEl: ".index-service-thumb-next",
    },
    breakpoints: {
      0: {
        loop: true,
        slidesPerView: 3,
      },
      480: {
        slidesPerView: 4,
      },
      640: {
        loop: false,
        slidesPerView: 7,
      },
    },
  });
  var indexService = new Swiper("#indexService", {
    thumbs: {
      swiper: indexServiceThumbs,
    },
  });


  var videoMenu = new Swiper("#videoMenu", {
    slidesPerView: 6,
    spaceBetween: 6,
    breakpoints: {
      0: {
        slidesPerView: 2.5,
      },
      480: {
        slidesPerView: 3.5,
      },
      640: {
        slidesPerView: 4.5,
      },
      1200: {
        slidesPerView: 6,
        navigation: {
          prevEl: ".video-list-prev",
          nextEl: ".video-list-next",
        },
      },
    },
  });

  var indexPartners = new Swiper("#indexPartners", {
    slidesPerView: 'auto',
    freeMode: true,
    autoplay: {
      delay: 0,
      // pauseOnMouseEnter:true,
      disableOnInteraction: false,
    },
    speed: 5000,
  });

  // 與其他家比較
  const fullmeCompareSlider = sliderElm => {
    return new Swiper(`#${sliderElm.id}`, {
      slidesPerView: 1,
      effect: 'fade',
      autoplay: {
        delay: 1500,
      },
      speed: 800,
    });
  }

  $('.about-compare-item').find('.swiper').each(function () {
    fullmeCompareSlider(this);
  });

  // 門市
  const StoreSlider = sliderElm => {
    return new Swiper(`#${sliderElm.id}`, {
      slidesPerView: 1,
      loop: true,
      speed: 1000,
      navigation: {
        nextEl: `.swiper-btn-next-${sliderElm.id}`,
        prevEl: `.swiper-btn-prev-${sliderElm.id}`
      },
    });
  }
  $('.store-swiper').each(function () {
    StoreSlider(this);
  });

  // 單諮信賴
  var fullmeCelebrity = new Swiper("#fullmeCelebrity", {
    slidesPerView: 4,
    spaceBetween: 10,
    centeredSlides: true,
    loop: true,
    speed: 500,
    pagination: {
      el: ".swiper-pagination",
      type: "fraction",
    },
    navigation: {
      prevEl: ".swiper-btn-prev",
      nextEl: ".swiper-btn-next",
    },
    breakpoints: {
      0: {
        slidesPerView: 2.5,
      },
      768: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
      2000: {
        slidesPerView: 5,
      },
      2300: {
        slidesPerView: 6,
      },
    },
  });

  var detailFail = new Swiper('#detailFail', {
    slidesPerView: 1,
    effect: 'fade',
    speed: 800,
    navigation: {
      prevEl: ".fail-btn-prev",
      nextEl: ".fail-btn-next",
    },
  });

  var fullmeRepair = new Swiper("#fullmeRepair", {
    slidesPerView: 1,
    spaceBetween: 70,
    speed: 500,
    navigation: {
      prevEl: ".repair-btn-prev",
      nextEl: ".repair-btn-next",
    },
  });

  var team_histories = new Swiper("#team_histories", {
    slidesPerView: 4.5,
    slidesAfter: 50,
    navigation: {
      prevEl: ".histories-btn-prev",
      nextEl: ".histories-btn-next",
    },
    breakpoints: {
      0: {
        spaceBetween: 10,
        slidesPerView: 1.2,
      },
      768: {
        spaceBetween: 20,
        slidesPerView: 2.5,
      },
      1000: {
        spaceBetween: 20,
        slidesPerView: 3.5,
      },
      1441: {
        spaceBetween: 30,
        slidesPerView: 4.5,
      },
    },
  });

  //門市環境
  var team_store = new Swiper("#team_store", {
    slidesPerView: 3,
    slidesCenter: true,
    freeMode: true,
    loop: true,
    autoplay: {
      delay: 0,
      disableOnInteraction: false,
    },
    speed: 6000,
    breakpoints: {
      0: {
        spaceBetween: 5,
        slidesPerView: 2,
      },
      768: {
        spaceBetween: 10,
        slidesPerView: 3,
      },
    },
  });

  var exp_swiper = new Swiper("#exp_swiper", {
    slidesPerView: 1,
    spaceBetween: 15,
    navigation: {
      prevEl: ".exp-prev",
      nextEl: ".exp-next",
    },
    breakpoints: {
      0: {
        slidesPerView: 1.2,
      },
      640: {
        slidesPerView: 2,
      },
      1200: {
        slidesPerView: 3,
        spaceBetween: 25,
      },
    },
  });

  var service_situation_swiper = new Swiper("#service_situation_swiper", {
    slidesPerView: 1,
    spaceBetween: 5,
    effect: 'fade',
    autoplay: {
      delay: 3000,
      // pauseOnMouseEnter:true,
      disableOnInteraction: false,
    },
    speed: 1000,
  });

  //服務項目 操作範圍
  var operatingImgs = new Swiper("#operatingImgs", {
    slidesPerView: 1,
    spaceBetween: 10,
    centeredSlides: true,
    loop: true,
    pagination: {
      el: ".swiper-pagination",
      type: "fraction",
    },
    navigation: {
      prevEl: ".swiper-btn-prev",
      nextEl: ".swiper-btn-next",
    },
  });

  //媒體報導
  $('.recommendation-news-btn').on('click', function() {
    const switch_check = document.getElementById("recommendation_news_switch").checked
    if (switch_check) {
      $(this).find('.open').css('display', 'none');
      $(this).find('.close').css('display', 'inline');
      $('#recommendation_news').addClass('full-height');
    }else {
      $('#recommendation_news').removeClass('full-height');
      $(this).find('.open').css('display', 'inline');
      $(this).find('.close').css('display', 'none');
    }
  });

  // dropdown
  $('.dropdown-btn').on('click', function () {
    const id = $(this).attr('id');
    const dropdown_is_active = $('[aria-labelledby=' + id + ']').hasClass("is-active");
    if (dropdown_is_active >= 1) {
      $('[aria-labelledby=' + id + ']').removeClass('is-active');
      if (window.innerWidth <= 640) {
        $('body').css({
          'height': '',
          'overflow': '',
        })
      }
    } else {
      $(".dropdown-list:not([aria-labelledby=" + id + "]").removeClass('is-active');
      $('[aria-labelledby=' + id + ']').addClass('is-active');
      if (window.innerWidth <= 640) {
        $('body').css({
          'height': '100vh',
          'overflow': 'hidden',
        })
      }
    }
  });

  $(document).click(function (event) {
    if (!$(event.target).closest('.dropdown-btn').length) {
      if ($('.dropdown-list').is(":visible")) {
        $(".dropdown-list").removeClass('is-active');
        if (window.innerWidth <= 640) {
          $('body').css({
            'height': '',
            'overflow': '',
          })
        }
      }
    }
  });

  // page-menu
  var windowWidth = $(window).width();

  if (windowWidth <= 900 && $('.sidebar-menu').length >= 1) {
    var item = $('.sidebar-menu-item.is-active').width();
    if ($('.sidebar-menu-item').hasClass('is-active')) {
      var item_left_size = $('.sidebar-menu-item.is-active').offset().left;
      if (item_left_size > (windowWidth/2 - item/2)) {
        var scrollTo = item_left_size - windowWidth/2 + item/2;
        $('.sidebar-menu').scrollLeft(scrollTo);
      };
    }
  }

  // 圖片zoom in
  var lightbox = new PhotoSwipeLightbox({
    gallery: '.custom_lightbox',
    children: 'a',
    showHideAnimationType: 'zoom',
    pswpModule: () => import('photoswipe'),
    counter: false
  });

  lightbox.addFilter('itemData', (itemData, index) => {
    const webpSrc = itemData.element.dataset.pswpWebpSrc;
    if (webpSrc) {
      itemData.webpSrc = webpSrc;
    }
    return itemData;
  });


  // use <picture> instead of <img>
  lightbox.on('contentLoad', (e) => {
    const { content, isLazy } = e;

    if (content.data.webpSrc) {
      // prevent to stop the default behavior
      e.preventDefault();

      content.pictureElement = document.createElement('picture');

      const sourceWebp = document.createElement('source');
      sourceWebp.srcset = content.data.webpSrc;
      sourceWebp.type = 'image/webp';

      const sourceJpg = document.createElement('source');
      sourceJpg.srcset = content.data.src;
      sourceJpg.type = 'image/jpeg';

      content.element = document.createElement('img');
      content.element.src = content.data.src;
      content.element.setAttribute('alt', '');
      content.element.className = 'pswp__img';

      content.pictureElement.appendChild(sourceWebp);
      content.pictureElement.appendChild(sourceJpg);
      content.pictureElement.appendChild(content.element);

      content.state = 'loading';

      if (content.element.complete) {
        content.onLoaded();
      } else {
        content.element.onload = () => {
          content.onLoaded();
        };

        content.element.onerror = () => {
          content.onError();
        };
      }
    }
  });

  lightbox.on('contentAppend', (e) => {
    const { content } = e;
    if (content.pictureElement && !content.pictureElement.parentNode) {
      e.preventDefault();
      content.slide.container.appendChild(content.pictureElement);
    }
  });

  lightbox.on('contentRemove', (e) => {
    const { content } = e;
    if (content.pictureElement && content.pictureElement.parentNode) {
      e.preventDefault();
      content.pictureElement.remove();
    }
  });

  lightbox.init();

  //scrollSpy
  const scrollspyOptions = {
    sectionClass: '.scrollspy',
    menuActiveTarget: '.scrollspy-link',
    offset: 150,
  }
  scrollSpy('.scroll-container', scrollspyOptions);

  AOS.init();

  //faq
  $('.accordion-item .accordion-title').click(function () {
    $(this).parent('.accordion-item').toggleClass('is-active').siblings().removeClass('is-active').find('.accordion-content').slideUp(200);
    $(this).siblings('.accordion-content').slideToggle(200);
  });

  // tab
  $('.tab[data-tab]').on('click', function () {
    var tabPanes = $(this).data('tabs');
    var ID = $(this).data('tab');
    $(this).addClass('is-active').siblings().removeClass('is-active');
    $("[data-tab-panes=" + tabPanes + "]").find("[data-tab-pane=" + ID + "]").addClass('is-active').siblings('.tab-pane').removeClass('is-active');
  });

  // 服務項目落髮階段換圖
  $('.service-level-item').hover(function() {
    let id = $(this).data('id');
    $(this).addClass('is-active').siblings('.service-level-item').removeClass('is-active');
    $('#' + id).show().siblings('.service-level-real-img').hide();
  });

  // 客製化高訂美學
  $('.service-customize-btn').on('click', function() {
    let id = $(this).data('customize');
    console.log(id)
    $(this).toggleClass('is-active');
    $('#' + id).toggleClass('is-active');
  });

  // before after 調整紋髮前後圖片
  $('.ba-range').on('input', function () {
    let id = $(this).attr('id');
    $('[data-switch='+id+']').css('opacity', $(this).val());
    if ($(this).val() == 1) {
      $(this).addClass('is-after');
    }else {
      $(this).removeClass('is-after');
    }
  });

  // 個角度紋髮前後圖片
  $('[data-filter-id]').on('change', function () {
    const id = $(this).attr('id');
    const design_before_after = $(this).find("input[type='radio'][name='design_before_after']:checked").val();
    const design_angle = $(this).find("input[type='radio'][name='design_angle']:checked").val();
    var active_img;
    if (design_before_after) {
      var active_img = id + "_" + design_angle + "_" + design_before_after;
    }else {
      var active_img = id + "_" + design_angle;
    }
    $('#' + active_img).show().siblings('.filter-img').hide();
  });

  // 設計線條開關
  $('.service-customize-line-btn').on('click', function () {
    let id = $(this).attr('id');
    $(this).toggleClass('is-active');
    $('[data-switch=' + id + ']').toggleClass('is-active');
  });

  // 全台門市 時間、地址、電話無資料時，隱藏該項icon
  $('.store-contact [data-icon]').each(function(){
    if ($(this).text().trim().length === 0) {
      $(this).css('display', 'none');
    }
  })
});
